import React from 'react';
import Button from '@material-ui/core/Button';

const Blog = () => {
    return (
        <div className="Blog-wrapper">
              <h2>Interested in implementing or improving e-learning?</h2>
              <a href="/contact-page"><Button className="demo-button">REQUEST A DEMO</Button></a>
        </div>
    )
}

export default Blog
