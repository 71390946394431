import * as React from 'react'

import {
    Grid,
    Card,
    CardContent,
    makeStyles,
    CardMedia,
    CardActions,
    Button,
    Link,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginRight: "10px",
        boxShadow: "none",
        borderRadius:"0",
        backgroundColor: "transparent",
        [theme.breakpoints.down("xs")]: {
            marginRight: "0px",
        },
    },
    cardMedia: {
 
        paddingTop: "56.25%", // 16:9
        position: "relative",
    },
    smallBanner: {
        position: "absolute",
        bottom: 20,
        right: 0,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        backgroundColor: "#255957",
        color: "#fff",
        padding: "10px 26px",
        [theme.breakpoints.down("sm")]: {
            right: 0,
            top: 20,
            bottom: "auto"
        },
        [theme.breakpoints.down("xs")]: {
            right: 0,
            bottom: 70,
            top: "auto",
        },
    },
    cardContent: {
        flexGrow: 1,
        minHeight: 150,
        [theme.breakpoints.down("sm")]: {
            minHeight: 175,
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: 140,
        },
    },
    meinGrid: {
        marginBottom: "150px",
        "& >h1, >h5": {
            textAlign: "center",
            paddingBottom: 30,
        },
    },
    cardWrapper: {
        border: "1px solid rgba(0,0,0,0.06)",
    
        background: "#fff",
    },
    more: {
        justifyContent: "flex-end",
        "& button": {
            color: "#255957",
            fontWeight: "bold",
        },
    },
    hover: {
        "&:hover": {
            textDecoration: "none",
        },
    },
    head: {
        textAlign: "center"
    },
    menuButton: {
        marginRight: theme.spacing(2),
        backgroundColor: "#255957",
        color: "#fff",
        border: "1px solid #255957",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#255957",
            border: "1px solid #255957",
        }
    },
}));

const MediaCard = (courseInfo => {
    const LOCAL_DOMAINS = [ "localhost", "127.0.0.1" ];
    let BASE_URL_PUBLIC = ""

    /* offline */
    if (LOCAL_DOMAINS.includes(window.location.hostname) )
    {
        BASE_URL_PUBLIC = "https://demo.eduxpress.de/";
    }
    
    /* online || production && staging */
    else
    {
        BASE_URL_PUBLIC = "";
    }

    const classes = useStyles();
    return (

        <Grid className="course" item key={courseInfo.courseInfo.course_id}>
            <Link href={ BASE_URL_PUBLIC +"courses/" + courseInfo.courseInfo.course_id + "/about"} className={classes.hover}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        title="Image title"
                        image={courseInfo.courseInfo.media.image.large}

                    >
                        {" "}<div
                            className={`${classes.smallBanner} small-banner`}
                        >
                            {courseInfo.courseInfo.org}
                        </div>
                    </CardMedia>

                    <div className={classes.cardWrapper}>
                        <CardContent className={classes.cardContent}>
                            <h3>
                               <strong> {courseInfo.courseInfo.name}</strong>
                            </h3>
                            <span>
                             <strong>Category:</strong> {courseInfo.courseInfo.org}
                            </span>
                            <div>
                            <strong>Number:</strong> {courseInfo.courseInfo.number}
                            </div>
                              <div>
                            </div>
                        </CardContent>
                        <CardActions className={classes.more}>
                            <Button size="small" color="primary">
                                More
                            </Button>
                        </CardActions>
                    </div>
                </Card>
            </Link>
        </Grid>

    );
})

export default MediaCard;
