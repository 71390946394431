import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import {
  List,
  ListItem,
  Divider,
  Link,
  Hidden,
  Drawer,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Logo from "../../images/logo.svg";
import useCookie from "./useCookies";
import { Menu, MenuItem } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import MenuIcon from "@material-ui/icons/Menu";

const drawerWidth = "75%";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "1rem",
  },
  header: {
    backgroundColor: "#000",
  },
  lasttitle: {
    flexGrow: 1,
    color: "#000",
    fontSize: "1rem",
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  desktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuButtonTop: {
    justifyContent: "flex-end",
    marginRight: 15,
  },
  closeicon: {
    float: "right",
    marginRight: 15,
    fontSize: "2rem",
  },
  buttonList: {
    width: "100%",
  },
  drawer: {
    "& ul:last-child": {
      marginTop: 40,
    },
    "& a": {
      color: "#255957",
    },
  },
  circlIcon: {
    color: "#00E2B6",
    "& svg": {
      fontSize: 40,
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoged, setIsLoged] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const cookie = useCookie("edxloggedin");
  const cookiesInfo = useCookie("edx-user-info");

  if (typeof cookiesInfo !== "undefined") {
    const replacedbad = cookiesInfo.replace(/\\054/g, ",");
    const cookiesJson = JSON.parse(JSON.parse(replacedbad));
    var userName = cookiesJson.username;
    var learner_profile = cookiesJson.header_urls.learner_profile;
    var account_settings = cookiesJson.header_urls.account_settings;
    var logout = cookiesJson.header_urls.logout;
  }

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    if (cookie === "true") {
      setIsLoged(true);
    }
  }, [cookie]);

  const drawer = (
    <div className={classes.drawer}>
      <List>
        <CloseIcon className={classes.closeicon} onClick={handleDrawerToggle} />
      </List>
      {!isLoged ? (
        <List>
          <ListItem>
            <Link
              className={`${classes.title} menu-item  menu-item-button`}
              href={"/news"}
            >
              NEWS
            </Link>
          </ListItem>
          <Divider className={classes.divider} />
          <ListItem>
            <Link
              className={`${classes.title} menu-item  menu-item-button`}
              href={"/contact-page"}
            >
              CONTACT US
            </Link>
          </ListItem>
          <Divider className={classes.divider} />
          <ListItem>
            <Link
              className={`${classes.title} menu-item  menu-item-button`}
              href={"/who-we-are"}
            >
              WHO WE ARE
            </Link>
          </ListItem>
          <Divider className={classes.divider} />
          <ListItem>
            <Link
              className={`${classes.title} menu-item  menu-item-button`}
              href={"/register"}
            >
              <Button
                className={"register-button"}
                variant="outlined"
                color="inherit"
              >
                REGISTER
              </Button>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              className={`${classes.title} menu-item menu-item-button`}
              href={"/login"}
            >
              <Button
                className={"login-button"}
                variant="outlined"
                color="inherit"
              >
                SIGN IN
              </Button>
            </Link>
          </ListItem>
          <Divider className={classes.divider} />
        </List>
      ) : (
        <List>
          <Divider className={classes.divider} />

          <MenuItem className={classes.profile}>
            <Link href="/dashboard">Dashboard</Link>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem className={classes.profile}>
            <Link href={learner_profile}>Profile</Link>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem>
            <Link href={account_settings}>Account</Link>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem className={classes.profile}>
            <Link href="/news">News</Link>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem className={classes.profile}>
            <Link href="/who-we-are">Who we are</Link>
          </MenuItem>
          <Divider className={classes.divider} />

          <MenuItem className={classes.profile}>
            <Link href="/contact-page">Contact us</Link>
          </MenuItem>
          <Divider className={classes.divider} />

          <MenuItem>
            <Link href={logout}>Log out</Link>
          </MenuItem>
        </List>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <AppBar position={"fixed"} className={classes.header}>
        <Toolbar className="header-desktop">
          <Link className="logo-link" href={"/"}>
            <img className="logo" src={Logo} alt="logo" />
          </Link>

          <NavLink className="who-link" to='/'  exact={true}>
            HOME
          </NavLink>

          <NavLink className="who-link" to={"/news"}>
            NEWS
          </NavLink>

          <NavLink className="who-link" to={"/contact-page"}>
            CONTACT US
          </NavLink>

          <NavLink className="who-link " to={"/who-we-are"}>
            WHO WE ARE
          </NavLink>
          <span className="last" />
          {!isLoged ? (
            <div className="login-buttons">
              <Link
                className={`${classes.title} menu-item  menu-item-button`}
                href={"/register"}
              >
                <Button
                  className={"register-button"}
                  variant="outlined"
                  color="inherit"
                >
                  REGISTER
                </Button>
              </Link>
              <Link
                className={`${classes.title} menu-item menu-item-button`}
                href={"/login"}
              >
                <Button
                  className={"login-button"}
                  variant="outlined"
                  color="inherit"
                >
                  SIGN IN
                </Button>
              </Link>
            </div>
          ) : (
            <div className="login-buttons">
              <p className="text">{userName}</p>
              <IconButton
                aria-haspopup="true"
                onClick={recordButtonPosition}
                className={classes.circlIcon}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={closeMenu}
              >
                <MenuItem className={classes.profile}>
                  <Link className="menu_link" href="/dashboard">
                    Dashboard
                  </Link>
                </MenuItem>
                <MenuItem className={classes.profile}>
                  <Link className="menu_link" href={learner_profile}>
                    Profile
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link className="menu_link" href={account_settings}>
                    Account
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link className="menu_link" href={logout}>
                    Log out
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>

        <div className="header-mobile">
          <NavLink to={"/"}>
            <img className="logo-mobile" src={Logo} alt="logo" />
          </NavLink>
          <div className="user_area">
            <p className="user-name">{userName}</p>
            <IconButton
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              className={classes.menuButtonTop}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
      </AppBar>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
}
