import React, { useState, useEffect } from "react";
import newsListJSON from "../../news.json";
import { Link } from "react-router-dom";
import { Card, Container, Grid, Button } from "@material-ui/core";

const newsPerPage = 3;
const NewsList = () => {
  const [newsToShow, setNewsToShow] = useState([]);
  const [next, setNext] = useState(3);

  const loopWithSlice = (start, end) => {
    const slicedPosts = newsListJSON.slice(start, end);
    setNewsToShow((news) => [...news, ...slicedPosts]);
  };

  useEffect(() => {
    loopWithSlice(0, newsPerPage);
  }, []);

  const handleShowMoreNews = () => {
    if (next + newsPerPage >= newsListJSON.length) {
      document.getElementById("load-more").setAttribute("disabled", "disabled");
    } else {
      document.getElementById("load-more").removeAttribute("disabled");
    }
    loopWithSlice(next, next + newsPerPage);
    setNext(next + newsPerPage);
  };

  return (
    <div className="newslist">

      <Container maxWidth={"md"}>
      <h1 className="title">NEWS</h1>
        {newsToShow.length &&
          newsToShow.map((news) => {
            return (
              <Card key={news.id} className="card">
                <Grid className="news-card" container>
                  <Grid item md={4} sm={4} xs={12}>
                    <div className="img-container">
                      {news.thumbnail && (
                        <img
                          className="thumbnail"
                          width={400}
                          src={news.thumbnail}
                          alt={news.title}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item md={8} sm={8} xs={12}>
                    <div className="news-body">
                      <h4 className="news-title">
                        <Link className="links" to={`/news/${news.id}`}>
                          {news.title}
                        </Link>
                      </h4>

                      <small>
                        Published on {news.date} by {news.author}
                      </small>

                      <p>{news.description}</p>
                      <small>
                        <Button
                          variant="contained"
                          color="primary"
                          className={"read-more"}
                          href={`/news/${news.id}`}
                        >
                          Read more
                        </Button>
                      </small>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        <Button id="load-more" onClick={handleShowMoreNews}>
          Load more
        </Button>
      </Container>
    </div>
  );
};

export default NewsList;
