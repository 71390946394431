import Images from "../../helper-logos";

const Logos = [
  {
    title: "Renewables Grid Initiative",
    image: Images["RGI.jpg"].default,
  },

  {
    title: "Hochschule Osnabrück",
    image: Images["hoschschule.jpg"].default,
  },

  {
    title: "Freie Universität Berlin",
    image: Images["universitat-berlin.jpg"].default,
  },

  {
    title: "Zukunft Lernen",
    image: Images["zukunft.jpg"].default,
  },

  {
    title: "Novareto",
    image: Images["novareto.jpg"].default,
  },
  {
    title: "Die BG ETEM",
    image: Images["bg-etem.jpg"].default,
  },

  {
    title: "Digital Druck Zentrum — DDZ",
    image: Images["ddz.jpg"].default,
  },

  {
    title: "der Freitag",
    image: Images["derfreitag.jpg"].default,
  },
  {
    title: "BG Verkehr",
    image: Images["bg-verkehr.jpg"].default,
  },
  {
    title: "Die BGHW",
    image: Images["bghw.jpg"].default,
  },

  {
    title: "The United Nations Institute for Training and Research (UNITAR)",
    image: Images["unitar-logo.jpg"].default,
  },
  {
    title: "Mannheim Business School",
    image: Images["mannheim-businees-school-logo.jpg"].default,
  },
  {
    title: "GFU Cyrus AG – Ihr Partner für IT-Schulungen",
    image: Images["gfu-cyrus-ag.jpg"].default,
    padding: "no-padding"
  },

  {
    title: "Die TUM",
    image: Images["logostum.jpg"].default,
  },

  {
    title: "EXASOL",
    image: Images["logoexasol.jpg"].default,
  },

  {
    title: "close2real",
    image: Images["logosc2r.jpg"].default,
  },

  {
    title: "Reporterfabrik",
    image: Images["rf.png"].default,
  },
  {
    title: "AnotherMonday",
    image: Images["logo_am.jpg"].default,
  },

  {
    title: "ESSEC",
    image: Images["logosessec.jpg"].default,
  },
  {
    title: "IEA - International Energy Agency",
    image: Images["iea-logo.jpg"].default,
  },
  {
    title: "Buergerakademie",
    image: Images["ba-logo.jpg"].default,
  },
  {
    title: "UEG",
    image: Images["ueg_logo.jpg"].default,
  },
  {
    title: "Mediendienstintegration",
    image: Images["mdi-logo.jpg"].default,
  },
  {
    title: "Kreiswert",
    image: Images["kreiswert-logo.jpg"].default,
  },
  {
    title: "GestOnline",
    image: Images["gest-logo.jpg"].default,
  },
  {
    title: "WZB",
    image: Images["wzb-logo.jpg"].default,
  },

  {
    title: "Limes",
    image: Images["limes-logo.jpg"].default,
  },
  {
    title: "Tools of the Mind",
    image: Images["tom-logo.jpg"].default,
  },

  {
    title: "polito",
    image: Images["polito-logo.jpg"].default,
  },

  {
    title: "DigiCirc",
    image: Images["digicirc-logo.jpg"].default,
  },
  {
    title: "IT ON BOARD",
    image: Images["itonboard-logo.jpg"].default,
  },
];

const FirstLogosSilder = () => {
  return (
    <div className="logos-container">
      <div className={"logo"}>
        {Logos.map((img) => (
          <img
            key={img.title}
            src={img.image}
            className={img.padding}
            alt={img.title}
            title={img.title}
          />
        ))}
      </div>
    </div>
  );
};

export default FirstLogosSilder;
