import React from "react";
import Layout from "../components/layout";
import CoverImage from "../components/who-we-are/Slider";
import Content from "../components/who-we-are/Content";

const WhoWeAre = () => {
  return (
    <React.Fragment>
      <div className="who-we-are">
        <Layout>
          <CoverImage />
          <Content />
        </Layout>
      </div>
    </React.Fragment>
  );
};

export default WhoWeAre;
