import Blog from "./Blog";

const Video = () => {
  return (
    <div className="video-section">
      <Blog />

      <video
        className="video"
        playsInline
        autoPlay
        loop
        muted
        src="https://cloud.abstract-technology.de/index.php/s/EzzLj4N4KzSJdHF/download"
      >
        <source
          src="https://cloud.abstract-technology.de/index.php/s/EzzLj4N4KzSJdHF/download"
          type="video/mp4"
        />
        <source
          src="https://abstract.it/++theme++abstractit.site/public/video/abstract-home.webm"
          type="video/webm"
        />
        <source
          src="https://abstract.it/++theme++abstractit.site/public/video/abstract-home.ogv"
          type="video/ogg"
        />
      </video>
      {/* </Container> */}
      {/* <h2>{VideoData[0].title}</h2>
      <Container maxWidth="md">
        <Grid container spacing={5}>
          <Grid item md={8} sm={12} xs={12}>
            <div className="player-wrapper-iframe">
              <ReactPlayer
                url={VideoData[0].youtubeURL}
                light={VideoData[0].coverImg}
                className="react-player"
                width="100%"
                playing
                height="100%"
                controls={true}
              />
            </div>
          </Grid>
          <Grid className="video-text" item md={4} sm={12} xs={12}>
            <ReactMarkdown
              source={VideoData[0].content}
              escapeHtml={false}
            ></ReactMarkdown>
          </Grid>
        </Grid>
      </Container> */}
    </div>
  );
};

export default Video;
