import React from "react";
import Layout from "../components/layout";
import Form from "../components/Main/Form";
import { config } from "../config";

const FormPage = () => {
  return (
    <React.Fragment>
     <div className="form-page">
      <Layout>
      <Form config={config} />
      </Layout>
      </div>
    </React.Fragment>
  );
};

export default FormPage;
