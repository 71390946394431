import React from "react";
import coverImage1 from "../../images/demo-slider1.jpg";
import coverImage2 from "../../images/demo-slider2.jpg";
import coverImage3 from "../../images/demo-slider3.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "@material-ui/core";


import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cover: {
    maxWidth: "100%",
    height: "auto",
    width: "100%",
  },
}));

const CoverImage = () => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.carousel}>
      <Container
        maxWidth={false}
        className="app-container"
        disableGutters={true}
      >
        <Slider {...settings}>
          <div className="cover-wrapper">
            <img
              className={classes.cover}
              src={coverImage1}
              alt="place-holder"
            />
          </div>
          <div className="cover-wrapper">
            <img
              className={classes.cover}
              src={coverImage2}
              alt="place-holder"
            />
          </div>
          <div className="cover-wrapper">
            <img
              className={classes.cover}
              src={coverImage3}
              alt="place-holder"
            />
          </div>
        </Slider>
      </Container>
    </div>
  );
};

export default CoverImage;
