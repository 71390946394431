import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

const Form = (props) => {
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const options = [
    { value: "Corporate", label: "Corporate" },
    { value: "NGOs", label: "NGOs" },
    { value: "School and Academic", label: "School and Academic" },
    { value: "Health", label: "Health" },
    { value: "Government", label: "Government" },
    { value: "others", label: "others" },
  ];

  const resetForm = () => {
    document.getElementById("contact-form").reset();
  };

  const handleFormSubmit = (data) => {
    axios({
      method: "post",
      cors: true,
      url: "/send_contact_email",
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then((result) => {
        if (result.data.status === "success") {
          setmailSent(result.data.status);
          setError(false);
          resetForm();
        } else {
          setError(true);
        }
      })
      .catch((error) => setError(error.message));
  };

  const { title, description, successMessage, errorMessage } = props.config;
  return (
    <div className="contact-form">
      <div className="contact-form__header">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="contact-form__container">
        <div>
          <form onSubmit={handleSubmit(handleFormSubmit)} id="contact-form">
            <label>First Name</label>
            <input
              type="text"
              placeholder={"Your First Name"}
              {...register("firstName", { required: false })}
            />
            <label>Last Name</label>
            <input
              type="text"
              placeholder={"Your Last Name"}
              {...register("lastName", { required: false })}
            />

            <label>Work Email *</label>
            <input
              type="email"
              placeholder={"Your Email"}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="warning">This field is required</span>
            )}

            <label>Organization *</label>
            <input
              type="text"
              placeholder={"Your Organization"}
              {...register("Organization", { required: true })}
            />
            {errors.Organization && (
              <span className="warning">This field is required</span>
            )}
            <label>Your sector *</label>
            <select {...register("sector", { required: true })} type="select" >
              <option disabled selected defaultValue="">
                {" "}
                -- select an option --{" "}
              </option>

              {options.map(({ value, label }, index) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
            </select>
            {errors.sector && (
              <span className="warning">This field is required</span>
            )}
            <label>Anything else to add</label>
            <textarea
              placeholder={"Write something....."}
              {...register("msg", { required: false })}
              type="textarea"
            />
            <input type="submit" />
          </form>
          {mailSent && <div className="sucsess">{successMessage}</div>}
          {error && <div className="error">{errorMessage}</div>}
        </div>
      </div>
    </div>
  );
};

export default Form;
