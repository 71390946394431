import React  from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import chatbotimg from "../../images/Nicole.jpg";

const BotRedirect = ({ url, message }) => {
  return (
    <div className="external-link">
      <a href={url} target="_blank" rel="noreferrer">
        {message}
      </a>
    </div>
  );
};

const Post = () => {
    return <div>Good to see you!</div>
};


const theme = {
  background: "#f5f8fb",
  headerBgColor: "#000",
  headerFontColor: "#fff",
  headerFontSize: "20px",
  botBubbleColor: "#00e2b6",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const config = {
  width: "350px",
  hideUserAvatar: true,
  placeholder: "Type your response.",
  headerTitle: ">_",
};

const Chatbot = (props) => {
  const steps = [
    {
      id: "welcome",
      message: "Hi there!, How are you doing? ",
      trigger: "firstname",
    },

    {
      id: "firstname",
      user: true,
      validator: (value) => {
        if (/^[A-Za-z ?!]+$/.test(value)) {
          return true;
        } else {
          return "Please input alphabet characters only.";
        }
      },
      trigger: "rmcbot",
    },
    {
      id: "rmcbot",
      message:
        "Nice to hear from you!, I am your Helper!, What can I do for you?",
      trigger: "qtype",
    },
    {
      id: "qtype",
      options: [
        { value: 1, label: "Request a demo course ?", trigger: "1" },
        { value: 2, label: "About us ", trigger: "2" },
      ],
    },
    {
      id: "1",
      component: <BotRedirect message="Request a Demo " url="/contact-page" />,
      trigger: "q-submit",
    },
    {
      id: "2",
      component: <BotRedirect message="Who we are" url="/who-we-are" />,
      trigger: "q-submit",
    },
    {
      id: "q-submit",
      message: "Do you have any other questions !?",
      trigger: "submit",
    },
    {
      id: "submit",
      options: [
        { value: "y", label: "Yes", trigger: "no-submit" },
        { value: "n", label: "No", trigger: "end-message" },
      ],
    },
    {
      id: "no-submit",
      options: [
        { value: 1, label: "Request a demo course ?", trigger: "1" },
        { value: 2, label: " About us ?", trigger: "2" },
      ],
    },
    {
      id: "end-message",
      component: <Post />,
      asMessage: true,
      end: true,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <ChatBot botAvatar={chatbotimg} steps={steps} floating={true} {...config} />
    </ThemeProvider>
  );
};

export default Chatbot;
