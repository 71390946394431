export const config = {
  title: "Contact Us",
  description: "How can we help?",
  successMessage: "Thank you for contacting us.",
  errorMessage: "Please fill the complete form or check the validation",
  fields: {
    firstName: "",
    lastName: "",
    email: "",
    organization:"",
    sector:"",
    msg: "",
  },
  fieldsConfig: [
    {
      id: 1,
      label: "First Name",
      fieldName: "firstName",
      type: "text",
      placeholder: "Your First Name",
      isRequired: false,
      klassName: "first-name-field",
    },
    {
      id: 2,
      label: "Last Name",
      fieldName: "lastName",
      type: "text",
      placeholder: "Your Last Name",
      isRequired: false,
      klassName: "last-name-field",
    },
    {
      id: 3,
      label: "Work Email *",
      fieldName: "email",
      type: "email",
      placeholder: " Your Email",
      isRequired: true,
      klassName: "email-field",
    },

    {
      id: 4,
      label: "Organization *",
      fieldName: "Organization",
      type: "text",
      placeholder: "Organization",
      isRequired: true,
      klassName: "organization-field",
    },
    {
      id: 5,
      label: "Your sector *",
      fieldName: "sector",
      type: "select",
      placeholder: "sector",
      isRequired: true,
      klassName: "sector-field",
    },
    {
      id: 6,
      label: "Anything else to add",
      fieldName: "msg",
      type: "textarea",
      placeholder: "Write something.....",
      isRequired: false,
      klassName: "message-field",
    },
  ],
};
