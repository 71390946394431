import React from "react";
import ReactMarkdown from "react-markdown";
import aboutText from "../../data/whoweare.json";

const Content = () => {
  return (
    <div className="content">
      <div>
        <ReactMarkdown
          source={aboutText[0].content}
          escapeHtml={false}
        ></ReactMarkdown>
      </div>
    </div>
  );
};

export default Content;
