

const getItem = key =>
    document.cookie.split("; ").reduce((total, currentCookie) => {
        const item = currentCookie.split("=");
        const storedKey = item[0];
        const storedValue = item[1];
        return key === storedKey ? decodeURIComponent(storedValue)  : total;
       
    }, "");

const useCookie = (key, defaultValue) => {

    const getCookie = () => getItem(key) || defaultValue;
    const cookie = getCookie();
    return cookie
};

export default useCookie;
