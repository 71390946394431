function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      return images[item.replace("./", "")] = r(item);
    });
  
    return images;
  }
  
  const ImagesLogos = importAll(require.context("./images/ClientsLogos", true));
  export default ImagesLogos;