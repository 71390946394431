import React from "react";
import ReactMarkdown from "react-markdown";
import bannerJSON from "../../data/banner.json";
const Banner = () => {
  return (
    <div className="banner-wrapper">
      <img src={bannerJSON[0].thumbnail} alt="banner" />
      <ReactMarkdown
        source={bannerJSON[0].content}
        escapeHtml={false}
      ></ReactMarkdown>
    </div>
  );
};

export default Banner;
