import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import FooterdataAddress from "../../data/address.json";
import FooterdataAboutUS from "../../data/aboutus.json";
import Logo from "../../images/logo.svg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  items: {
    display: "flex",
    justifyContent: "start",
    color: "#000",
    listStyleType: "none",
    marginLeft: 22,
    paddingTop: 44,
    paddingBottom: 22,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
      flexDirection: "column",
      padding: "0 16px",
      marginLeft: 0,
    },
  },
  list: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
      marginTop: 16,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div component="footer" className={classes.footer}>
      <Grid container spacing={2} className="footer_grid">
        <Grid item lg={3} md={6} sm={6} xs={12}>
          <Link href="/">
            <img src={Logo} alt="logo" />
          </Link>
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12} className="about-us">
        <h1> {FooterdataAboutUS[0].title}</h1>
          <ReactMarkdown
            source={FooterdataAboutUS[0].content}
            escapeHtml={false}
          ></ReactMarkdown>
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}  className="contact">
        <h1> {FooterdataAddress[0].title}</h1>
        
        <ReactMarkdown
          source={FooterdataAddress[0].content}
          escapeHtml={false}
        ></ReactMarkdown>
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <h1>Follow us on social media</h1>

          <a
            className="social-list_link twitter"
            rel="noreferrer"
            href="https://twitter.com/abstractGmbH"
            target="_blank"
          >
            <TwitterIcon />
          </a>
          <a
            className="social-list_link linkedin"
            rel="noreferrer"
            href="https://www.linkedin.com/company/abstract-technology-gmbh/"
            target="_blank"
          >
            {" "}
            <LinkedInIcon />
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
