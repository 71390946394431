import FirstLogosSilder from "./LogosSlider1";


const OurClients = () => {
  return (
    
      <div className="logos-wrapper">
        <h2>We have been chosen by : </h2>
      <FirstLogosSilder />

    </div>
  );
};

export default OurClients;
