import React from "react";
import Markdown from "react-markdown";
import Layout from "../layout";
import newsList from "../../news.json";
import { Container, Button } from "@material-ui/core";
const NewsItem = (props) => {
  const validId = parseInt(props.match.params.id);
  if (!validId) {
    return;
  }
  const fetchedNews = {};
  let newsExists = false;
  newsList.forEach((news, i) => {
    if (validId === news.id) {
      fetchedNews.title = news.title ? news.title : "No title given";
      fetchedNews.date = news.date ? news.date : "No date given";
      fetchedNews.description = news.description
        ? news.description
        : "No date given";
      fetchedNews.author = news.author ? news.author : "No author given";
      fetchedNews.content = news.content ? news.content : "No content given";
      fetchedNews.thumbnail = news.thumbnail
        ? news.thumbnail
        : "No content given";
      newsExists = true;
    }
  });
  if (newsExists === false) {
    return;
  }
  return (
    <Layout>
      <Container maxWidth={"md"}>
        <div className="news-item">
          <h1 className="title">{fetchedNews.title}</h1>
          <small className="published-item">
            Published on {fetchedNews.date} by {fetchedNews.author}
          </small>
          <span className="description"> {fetchedNews.description} </span>
          <br />
          <img
            className="image-news-page"
            src={fetchedNews.thumbnail}
            alt={fetchedNews.title}
          />

          <br />
          <Markdown source={fetchedNews.content} escapeHtml={false} />
          <Button
            variant="contained"
            color="primary"
            className={"other-news"}
            href={`/news/`}
          >
            back to the news
          </Button>
        </div>
      </Container>
    </Layout>
  );
};

export default NewsItem;
