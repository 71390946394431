import React, { useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import MediaCard from "../components/Main/MediaCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CircularProgress } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import Banner from "../components/Main/Banner";
import Images from "../helper";
import {
  Business,
  Career,
  Health,
  Management,
  Programming,
  Technology,
} from "../components/SliderText/TextDescription";
import OurClients from "../components/Main/OurClients";
import Video from "../components/Main/video";

const btnList = [
  {
    title: "Technology",
    image: Images["category-card.jpg"].default,
  },

  {
    title: "Business",
    image: Images["category-card2.jpg"].default,
  },

  {
    title: "Programming",
    image: Images["category-card3.jpg"].default,
  },

  {
    title: "Health",
    image: Images["category-card4.jpg"].default,
  },

  {
    title: "Management",
    image: Images["category-card.jpg"].default,
  },
  {
    title: "Career",
    image: Images["category-card2.jpg"].default,
  },
  {
    title: "ITONBOARD",
    image: Images["category-card3.jpg"].default,
  },
  {
    title: "Abstract",
    image: Images["category-card.jpg"].default,
  },
];

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [coursesDataAndAI, setCoursesDataAndAI] = useState(null);
  const [activeButtonId, setActiveButtonId] = useState(null);
  const [isOneActive, setIsOneActive] = useState(null);
  const update_data = async function() {
    setIsLoading(true);

    const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
    let BASE_URL_PUBLIC = "";

    /* offline */
    if (LOCAL_DOMAINS.includes(window.location.hostname)) {
      BASE_URL_PUBLIC = "https://demo.eduxpress.de/api/courses/v1/courses/";
    } else {
      /* online || production && staging */
      BASE_URL_PUBLIC = "/api/courses/v1/courses/";
    }

    const result = await axios(BASE_URL_PUBLIC, {
      params: { page_size: 100 },
    });
    // TODO: sort results by start date
    setData(
      result.data.results.sort((a, b) => new Date(b.start) - new Date(a.start))
    );

    setCoursesDataAndAI(
      result.data.results.filter((item) => {
        return (
          
          item.categories.map(category => category.toLowerCase()).includes("ai") ||
          item.categories.map(category => category.toLowerCase()).includes("data")
        );
      })
    );

    setIsLoading(false);
  };
  React.useEffect(() => {
    update_data();
  }, []);

  let filteredData = null;
  if (activeButtonId != null) {
    filteredData = data
      ? data.filter((el) => {
          return (
            btnList[activeButtonId].title.toLowerCase() === el.org.toLowerCase()
          );
        })
      : [];
  }
  let notfilteredData = data;
  let filteredCourseByDataAndAI = coursesDataAndAI;

  const settings = {
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "60px",
    slidesToScroll: 4,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          centerMode: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleToggle = (e, btn_id) => {
    if (e.target.parentNode.classList.contains("is_active")) {
      setIsOneActive(null);
      setActiveButtonId(null);
    } else {
      setIsOneActive("not_active");
      setActiveButtonId(btn_id);
    }
  };

  const closeButton = () => {
    setIsOneActive(null);
    setActiveButtonId(null);
  };

  const setCourseslength = (length) => {
    const oneCourse = " course";
    const manyCourse = " courses";
    if (length === 1) {
      return length + oneCourse;
    } else {
      return length + manyCourse;
    }
  };

  const settext = (org) => {
    switch (org) {
      case "Business":
        return <Business />;
      case "Technology":
        return <Technology />;
      case "Programming":
        return <Programming />;
      case "Health":
        return <Health />;
      case "Management":
        return <Management />;
      case "Career":
        return <Career />;
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <div className="home-page">
        <Layout>
          <Banner />
          {isLoading ? (
            <div className="loader-wrapper">
              <CircularProgress className="loader" />
            </div>
          ) : (
            <div className="slider-container just_sorted">
              <h2> Browse our newest learning content</h2>
              <Slider {...settings}>
                {notfilteredData.map((courseInfo) => (
                  <div key={courseInfo.id}>
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {isLoading ? (
            <div className="loader-wrapper">
              <CircularProgress className="loader" />
            </div>
          ) : (
            <div className="slider-container just-filterd">
              <div>
                <h2>
                  {" "}
                  Start Learning with free courses or Discover lifelong learning
                </h2>

                <div>
                  <Slider {...settings} className="first_sli">
                    {btnList.map((btn, btn_id) => (
                      <div
                        key={btn_id}
                        className={
                          btn_id === activeButtonId ? "is_active" : isOneActive
                        }
                        name={btn.title}
                        onClick={(event) => handleToggle(event, btn_id)}
                        id="menuButton"
                      >
                        <img src={btn.image} alt={btn.title} />

                        <h3>{btn.title}</h3>
                        <KeyboardArrowDownIcon />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>

              {filteredData && filteredData.length > 0 ? (
                <div className="filter-result">
                  <CloseIcon className="close_button" onClick={closeButton} />

                  <span className="course_length">
                    {" "}
                    {setCourseslength(filteredData.length)} | Language: English{" "}
                  </span>
                  {filteredData.map((el) => {
                    return (
                      <div className="course_desc" key={el.id}>
                        <p className="org_name">{el.org}</p>
                        <p>{settext(el.org)}</p>
                      </div>
                    );
                  })}

                  <br />

                  <Slider {...settings} className="filter-cards">
                    {filteredData.map((courseInfo) => (
                      <div key={courseInfo.id}>
                        <MediaCard
                          key={courseInfo.id}
                          className="course"
                          courseInfo={courseInfo}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <React.Fragment />
              )}
            </div>
          )}

          <div className="slider-container just-filterd">
            <h2>Select your course between AI and Data </h2>
            <div>
              {filteredCourseByDataAndAI &&
              filteredCourseByDataAndAI.length > 0 ? (
                <Slider {...settings}>
                  {filteredCourseByDataAndAI.map((courseInfo) => (
                    <div key={courseInfo.id}>
                      <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                    </div>
                  ))}
                </Slider>
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>

        </Layout>
        <OurClients />
        <Video />
      </div>
    </React.Fragment>
  );
};

export default Home;
