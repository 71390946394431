import React from "react";

export const Business = () => {
  return (
    <React.Fragment>
      Level-up your CV with these top courses designed to teach you in-demand
      management and business skills. All courses are only{" "}
      <strong>examples</strong> and{" "}
      <strong> contents only for demonstration purposes</strong> of the company
      abstract-technology.
    </React.Fragment>
  );
};

export const Career = () => {
  return (
    <React.Fragment>
      Level-up your CV with these top courses designed to teach you in-demand
      Career and HR skills. All courses are only <strong>examples</strong>
      and <strong> contents only for demonstration purposes </strong> of the
      company abstract-technology.
    </React.Fragment>
  );
};

export const Health = () => {
  return (
    <React.Fragment>
      Level-up your CV with these top courses designed to teach you in-demand
      Health skills. All courses are only <strong>examples</strong> and contents
      only for demonstration purposes of the company abstract-technology.
    </React.Fragment>
  );
};

export const Management = () => {
  return (
    <React.Fragment>
      Level-up your CV with these top courses designed to teach you in-demand
      Management and HR skills. All courses are only
      <strong>examples</strong> and
      <strong>contents only for demonstration purposes</strong> of the company
      abstract-technology.
    </React.Fragment>
  );
};

export const Programming = () => {
  return (
    <React.Fragment>
      Level-up your CV with these top courses designed to teach you in-demand
      programming and IT skills. All courses are only <strong>examples</strong>
      and <strong> contents only for demonstration purposes </strong> of the
      company abstract-technology.
    </React.Fragment>
  );
};

export const Technology = () => {
  return (
    <React.Fragment>
      Level-up your CV with these top courses designed to teach you in-demand
      tech and IT skills. Learn new skills with a modern online course. All
      courses are only <strong>examples</strong> and{" "}
      <strong> contents only for demonstration purposes </strong> of the company
      abstract-technology.
    </React.Fragment>
  );
};
