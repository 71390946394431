import React from 'react';
import ReactDOM from 'react-dom';
import './sass/app.scss';
import Home from "./pages/home"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import reportWebVitals from './reportWebVitals';
import Header from './components/Header/header'
import Footer from './components/Footer/footer';
import WhoWeAre from './pages/about-us'
import FormPage from './pages/form-page';
import Chatbot from './components/Main/chatbot'
import NewsList from './components/news/news';
import NewsItem from './components/news/newsitem'
import BackToTop from './components/Main/BackToTop'


ReactDOM.render(
  <Router>
    <Header />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/who-we-are" component={WhoWeAre} />
      <Route exact path="/contact-page" component={FormPage} />
      <Route exact path="/news" component={NewsList} />
      <Route exact path="/news/:id" render={props => <NewsItem {...props} />} />
    </Switch>
    <Chatbot/>
    <Footer />
    <BackToTop/>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
